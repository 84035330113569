import moment from 'moment-timezone';
import React, { MutableRefObject, useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as AtSign } from '../../../images/at_sign.svg';
import { ReactComponent as ClockSVG } from '../../../images/clock_green5.svg';
import { ReactComponent as Phone } from '../../../images/phone.svg';
import { howManyDaysBetween } from '../../../utils/calcHelpers';
import { phoneLink } from '../../../utils/formatStrings';

import './opportunityContactInfo.scss';

const OpportunityContactInfo: React.FC<{
  contactFirstname: string;
  contactLastname: string;
  phoneNumber: string;
  email: string;
  website: string;
  deadline: string;
  contactRef: MutableRefObject<null | HTMLDivElement>;
}> = ({
  contactFirstname,
  contactLastname,
  phoneNumber,
  email,
  website,
  deadline,
  contactRef,
}) => {
  const { i18n } = useContext(GlobalContext);

  // Date only so set it in our TZ
  const daysLeft = howManyDaysBetween(
    moment.tz(deadline + ' 23:59:59', 'America/Montreal'),
    moment().utc()
  );

  return (
    <div className="opp-contact-info-container opp-section" ref={contactRef}>
      <div className="heading">
        {i18n?.t(`opportunity.details.contact_info`)}
      </div>
      <div
        className="info"
        style={{
          paddingTop: !contactFirstname && !contactLastname ? '40px' : '30px',
        }}
      >
        {(contactFirstname || contactLastname) && (
          <h3>
            {contactFirstname && `${contactFirstname} `}
            {contactLastname}
          </h3>
        )}

        {phoneNumber && (
          <div className="info-line">
            <div className="svg">
              <Phone />
            </div>
            <span>
              <a href={phoneLink(phoneNumber)}>{phoneNumber}</a>
            </span>
          </div>
        )}
        {email && (
          <div className="info-line">
            <div className="svg">
              <AtSign />
            </div>
            <span>
              <a href={`mailto:${email}`}>{email}</a>
            </span>
          </div>
        )}
        {website && (
          <a href={website} target="_blank" className="bid-link">
            {i18n?.t(`opportunity.details.bid_link`)}
          </a>
        )}
      </div>

      <div className="deadline">
        <ClockSVG />
        <span>
          {i18n?.t(`opportunity.details.bid_deadline`)}:{' '}
          <span className="bold">{`${deadline}`}</span>
          {daysLeft > 0 && (
            <p>{`(${daysLeft} ${i18n?.t(
              `opportunity.details.day${daysLeft > 1 ? 's' : ''}_left`
            )})`}</p>
          )}
        </span>
      </div>
    </div>
  );
};

export default OpportunityContactInfo;
