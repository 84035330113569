import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import CreeCertified from '../../global/creeCertified/creeCertified';
import { ReactComponent as ArrowSVG } from '../../../images/circle_right.svg';

import './opportunityBusinessInfo.scss';

const OpportunityBusinessInfo: React.FC<{
  businessName: string;
  about: string;
  isProvider: boolean;
  businessLink: string;
  creeCertified: boolean;
}> = ({ businessName, about, isProvider, businessLink, creeCertified }) => {
  const { i18n } = useContext(GlobalContext);

  if (about?.length > 500) about = about.substring(0, 500).concat('...');

  return (
    <div className="opp-about-business">
      <div className="about-heading">
        {!isProvider && (
          <a
            href={businessLink}
            target={'_blank'}
            className="business-page-link"
          >
            <span>{i18n?.t(`opportunity.details.see_more`)}</span>
            <ArrowSVG />
          </a>
        )}
        <div
          className="name-container"
          style={{ marginRight: isProvider ? 'auto' : 0 }}
        >
          {creeCertified && <CreeCertified />}
          <h3>
            {i18n?.t(`opportunity.details.about`)} {businessName}
          </h3>
        </div>
      </div>
      <div className="about-description">
        <p>{about}</p>
      </div>
    </div>
  );
};

export default OpportunityBusinessInfo;
