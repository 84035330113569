import moment from 'moment-timezone';
import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { opportunityStatusEnum } from '../../../types/opportunities';
import { isItNew } from '../../../utils/booleanChecks';
import { getCssClassFromOpportunityStatus } from '../../../utils/cssUtils';
import { formatDateMMMDDYYYY } from '../../../utils/formatStrings';

import './opportunityHeading.scss';

const OpportunityHeading: React.FC<{
  status: string;
  title: string;
  provider: string;
  publishedAt: Date;
  isProvider: boolean;
  businessLink: string;
}> = ({ status, title, provider, publishedAt, isProvider, businessLink }) => {
  const { i18n } = useContext(GlobalContext);
  const formattedDate = formatDateMMMDDYYYY(publishedAt);
  const isNew = isItNew(moment(publishedAt));

  const opportunityStatusCssClass = getCssClassFromOpportunityStatus(status);

  return (
    <div className="opp-info-heading">
      <div className="status-update-date">
        {isNew && status === opportunityStatusEnum.open && (
          <div className={`new`}>{`New`}</div>
        )}
        <div className={`status ${opportunityStatusCssClass}`}>
          {i18n?.t(`status.${opportunityStatusCssClass}`)}
        </div>
        <span className="date">{formattedDate ?? ''}</span>
      </div>
      <div className="title-provider">
        <h2>{title}</h2>
        <p>
          {i18n?.t(`opportunity.details.provided_by`)} :{' '}
          {!isProvider ? (
            <a href={businessLink} target="_blank" className="provider">
              {provider}
            </a>
          ) : (
            provider
          )}
        </p>
      </div>
    </div>
  );
};

export default OpportunityHeading;
