import React from 'react';

import './opportunityDescription.scss';

const OpportunityDescription: React.FC<{
  tags: { attributes: { name: string } }[];
  description: string;
}> = ({ tags, description }) => {
  return (
    <div className="opp-description">
      <div
        className="details"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className="detail-tags">
        {tags.map((tag, index) => {
          return (
            <div
              className="detail-tag"
              key={`
            ${index}-${tag.attributes.name}`}
            >
              {tag.attributes.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OpportunityDescription;
