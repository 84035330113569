import { ImageAttributes, InstanceData } from './general';

export type OpportunitiesLocalizationData = {
  attributes: {
    slug: string;
    locale: string;
  };
};

export type OpportunitiesData = {
  attributes: {
    slug: string;
    locale: string;
    localizations: {
      data: Array<OpportunitiesLocalizationData>;
    };
  };
};

export type OpportunityHit = {
  id: string;
  slug: string;
  claimedBy?: string;
  claimed_by_businesses?: string[];
  contractEndDate?: string;
  contractStartDate?: string;
  deadline?: string;
  deadline_date?: number;
  estimated_value?: string;
  industry_tags?: string[];
  is_claimed?: boolean;
  locations?: string[];
  provider?: string;
  publication_date?: string[];
  publishedAt?: string;
  published_at?: number;
  status?: string[];
  title?: string;
};

export type OpportunityData = InstanceData & {
  id: number;
  attributes: {
    slug: string;
    title: string;
    description: string;
    website: string;
    contactFirstname: string;
    contactLastname: string;
    email: string;
    phone: string;
    address: string;
    deadline: string;
    contractStartDate: string;
    contractEndDate: string;
    isClaimed: boolean;
    claimedBy: string;
    status: string;
    updatedAt: Date;
    publishedAt: Date;
    opportunityProvider: {
      data: {
        attributes: {
          name: string;
          description: string;
          website: string;
          email: string;
          phone: string;
          logo: {
            data: {
              attributes: ImageAttributes;
            };
          };
        };
      };
    };
    business: {
      data: {
        attributes: {
          slug: string;
          publicFacingLegalBusinessName: string;
          publicFacingDescription: string;
          website: string;
          email: string;
          phone: string;
          address: string;
          locale: string;
          creeCertified: boolean;
          logo: {
            data: {
              attributes: ImageAttributes;
            };
          };
        };
      };
    };
    claimedByBusinesses: {
      data: Array<{
        attributes: {
          slug: string;
          publicFacingLegalBusinessName: string;
          locale: string;
        };
      }>;
    };
    estimatedValue: {
      data: {
        attributes: {
          name: string;
        };
      };
    };
    locations: {
      data: Array<{
        attributes: {
          name: string;
        };
      }>;
    };
    industryTags: {
      data: Array<{
        attributes: {
          name: string;
        };
      }>;
    };
    localizations: {
      data: Array<{
        id: number;
        attributes: {
          slug: string;
          title: string;
          locale: string;
        };
      }>;
    };
  };
};

export enum opportunityStatusEnum {
  draft = 'Draft',
  denied = 'Denied',
  pendingForApproval = 'Pending_For_Approval',
  closedAndGranted = 'Closed_And_Granted',
  closed = 'Closed',
  open = 'Open',
}
