import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';

import { ReactComponent as Pin } from '../../../images/pin.svg';
import { ReactComponent as ArrowSVG } from '../../../images/circle_right.svg';
import { ReactComponent as Calendar } from '../../../images/calendar_calendar.svg';
import { ReactComponent as Dollar } from '../../../images/dollar_greenmain.svg';

import './opportunityImportantInfo.scss';
import { getUploadUrl } from '../../../utils/general';

const OpportunityImportantInfo: React.FC<{
  locations: { attributes: { name: string } }[];
  address: string;
  startDate: string;
  endDate: string;
  estimatedValue: string;
  logo: string;
}> = ({ locations, address, startDate, endDate, estimatedValue, logo }) => {
  const { i18n } = useContext(GlobalContext);

  let commaPosition: number;
  let addressLine1: string = '';
  let addressLine2: string = '';

  // format address into 2 lines
  if (address?.includes(',')) {
    commaPosition = address.split('').indexOf(',');
    addressLine1 = address.slice(0, commaPosition);
    addressLine2 = address.slice(commaPosition + 1);
  }
  if (address && !address?.includes(',')) addressLine1 = address;

  return (
    <div className="opp-important-info-wrapper opp-section">
      {logo && <img src={getUploadUrl(logo)} alt="provider-logo" />}

      <div className="info-container">
        <div className="svg">
          <Pin />
        </div>
        <div className="info">
          {locations.length > 0 && (
            <div className={`detail-tags ${addressLine1 ? 'address' : ''}`}>
              {locations.map((location, index) => {
                return (
                  <div
                    className="detail-tag"
                    key={`
                  ${index}-${location.attributes.name}`}
                  >
                    {location.attributes.name}
                  </div>
                );
              })}
            </div>
          )}

          {addressLine1 && (
            <>
              <p>{addressLine1}</p>
              {addressLine2 && <p>{addressLine2}</p>}
            </>
          )}
          {address && (
          <a
            className="directions-link"
            href={`https://www.google.com/maps/search/${address.replaceAll(
              ' ',
              '+'
            )}`}
            target="_blank"
          >
            {i18n?.t('business_page.directions')} <ArrowSVG />
          </a>
          )}
        </div>
      </div>

      <div className="info-container">
        <div className="svg">
          <Calendar />
        </div>
        <div className="info">
          <span>
            {i18n?.t(`opportunity.details.starting_date`)}:{' '}
            <span className="bold"> {startDate}</span>
          </span>
          <span>
            {i18n?.t(`opportunity.details.end_date`)}:{' '}
            <span className="bold"> {endDate}</span>
          </span>
        </div>
      </div>

      <div className="info-container">
        <div className="svg">
          <Dollar />
        </div>
        <div className="info">
          <span> {i18n?.t(`opportunity.details.estimated_value`)}:</span>
          <span className="bold">{estimatedValue}</span>
        </div>
      </div>
    </div>
  );
};

export default OpportunityImportantInfo;
