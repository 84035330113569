import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as ArrowSVG } from '../../../images/circle_right.svg';
import { buildPathForDetailsPage } from '../../../utils/formatStrings';

import './opportunityClaimedByInfo.scss';

const OpportunityClaimedByInfo: React.FC<{
  claimedByBusinesses: Array<{
    attributes: {
      slug: string;
      publicFacingLegalBusinessName: string;
      locale: string;
    };
  }>;
}> = ({ claimedByBusinesses }) => {
  const { i18n } = useContext(GlobalContext);

  return (
    <div className="opp-claimed-by-info-container opp-section">
      <div className="heading">{i18n?.t(`opportunity.details.awardees`)}</div>
      <div className="info">
        {claimedByBusinesses.length ? (
          claimedByBusinesses.map((business, index) => {
            const businessLink = buildPathForDetailsPage(
              business.attributes.slug,
              'business_page',
              business.attributes.locale,
              i18n
            );
            return (
              <>
                {index > 0 && <hr className="opp-divider" />}
                <div className="info-line">
                  <p className="specified">
                    {business.attributes.publicFacingLegalBusinessName}
                  </p>
                  <a
                    href={businessLink}
                    target={'_blank'}
                    className="business-page-link"
                  >
                    <span>{i18n?.t(`opportunity.details.see_more`)}</span>
                    <ArrowSVG />
                  </a>
                </div>
              </>
            );
          })
        ) : (
          <div className="info-line">
            <p className="unspecified">
              {i18n?.t(`opportunity.details.unspecified_awardee`)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunityClaimedByInfo;
