import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import Loading from '../components/global/loading/loading';
import OpportunityPageMain from '../components/opportunity/opportunityPageMain/opportunityPageMain';
import { PageBase } from '../context/global-context';
import NotFoundPage from '../pages/404';
import { PostType } from '../types/general';
import { OpportunityData } from '../types/opportunities';
import { PageContext } from '../types/page';
import { LocaleEnum, useI18n } from '../utils/i18n';

type OpportunityContext = PageContext & {
  locale: LocaleEnum;
};

const OpportunityPage: React.FC<{
  slug: string;
  pageContext: OpportunityContext;
}> = ({ slug, pageContext }) => {
  const { locale, translations } = pageContext;
  const [instance, setInstance] = useState<OpportunityData>();
  const [loading, setLoading] = useState(true);

  const i18n = useI18n(translations);

  useEffect(() => {
    try {
      // Use collection instead of single endpoint
      // single endpoint return draft data and use id, can't filter to get current local and slug
      // Can't use populate=* to get all 1st level relation with nested relation, so have to specify 1st level relation
      axios
        .get(
          `${process.env.GATSBY_STRAPI_URL}/api/opportunities` +
            `?locale=${locale}` +
            `&filters[slug][$eq]=${slug}` +
            `&populate=opportunityProvider.logo,business.logo,claimedByBusinesses,estimatedValue,locations,industryTags,localizations`
        )
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.data && responseData.data.length === 1) {
              const instance: OpportunityData = {
                postType: PostType.opportunity,
                ...responseData.data[0],
              };

              setInstance(instance);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  if (!loading && !instance) {
    return <NotFoundPage pageContext={pageContext} />;
  }

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          title={i18n.t('opportunity.details.banner.title')}
          bannerType="opportunity-info"
          pageType={pageType.item}
          crumbs={{
            previousPageTitle: i18n.t('opportunities.title'),
            previousPageLink: i18n.t('opportunities.slug'),
            crumbTitle: i18n.t('opportunity.details.crumbs.title'),
          }}
        />
        {loading && (
          <div style={{ padding: '24px 0px' }}>
            <Loading size="large" />
          </div>
        )}
        {!loading && instance && <OpportunityPageMain opportunity={instance} />}
      </Layout>
    </PageBase>
  );
};

export default OpportunityPage;
