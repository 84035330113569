import React, { useContext, useRef } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import {
  OpportunityData,
  opportunityStatusEnum,
} from '../../../types/opportunities';
import {
  buildPathForDetailsPage,
  upperFirstChar,
} from '../../../utils/formatStrings';
import { LocaleType } from '../../../utils/i18n';
import { getOpportunityStatus } from '../../../utils/opportunity';
import Register from '../../homepage/register/register';
import OpportunityBusinessInfo from '../opportunityBusinessInfo/opportunityBusinessInfo';
import OpportunityClaimedByInfo from '../opportunityClaimedByInfo/opportunityClaimedByInfo';
import OpportunityContactInfo from '../opportunityContactInfo/opportunityContactInfo';
import OpportunityHeading from '../opportunityHeading/opportunityHeading';
import OpportunityImportantInfo from '../opportunityImportantInfo/opportunityImportantInfo';
import OpportunityDescription from '../opporunityDescription/opportunityDescription';

import './opportunityPageMain.scss';

const OpportunityPageMain: React.FC<{
  opportunity: OpportunityData;
}> = ({ opportunity }) => {
  const {
    address,
    business,
    claimedByBusinesses,
    contactFirstname,
    contactLastname,
    contractEndDate,
    contractStartDate,
    deadline,
    description,
    email,
    estimatedValue,
    industryTags,
    locations,
    opportunityProvider,
    phone,
    publishedAt,
    title,
    website,
    status,
  } = opportunity.attributes;

  const currentLocale: LocaleType = useLocaleFromRouter();
  const { i18n } = useContext(GlobalContext);
  const contactRef = useRef<null | HTMLDivElement>(null);

  const opportunityStatus = getOpportunityStatus(
    status,
    publishedAt
  );

  const oppLogo =
    opportunityProvider.data?.attributes?.logo.data?.attributes.url ??
    business.data?.attributes?.logo.data?.attributes.url;

  const isProvider = opportunityProvider.data?.attributes.name ? true : false;
  let businessLink = '';
  if (!isProvider)
    businessLink = buildPathForDetailsPage(
      business.data?.attributes.slug,
      'business_page',
      business.data?.attributes.locale,
      i18n
    );

  const scrollToContactInfo = () => {
    if (contactRef.current)
      window.scrollTo({
        top: contactRef.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
  };

  return (
    <div className="opportunity-page-wrapper">
      <div className="opportunity-info-wrapper">
        <div className="main-info-container opp-section">
          <OpportunityHeading
            status={opportunityStatus}
            publishedAt={publishedAt}
            title={title}
            isProvider={isProvider}
            provider={
              opportunityProvider.data?.attributes.name ??
              business.data?.attributes.publicFacingLegalBusinessName
            }
            businessLink={businessLink}
          />

          <hr className="opp-divider" />

          <OpportunityDescription
            tags={industryTags.data}
            description={description}
          />

          <hr className="opp-divider" />

          <OpportunityBusinessInfo
            businessName={
              opportunityProvider.data?.attributes.name ??
              business.data?.attributes.publicFacingLegalBusinessName
            }
            about={
              opportunityProvider.data?.attributes.description ??
              business.data?.attributes.publicFacingDescription
            }
            creeCertified={business.data?.attributes.creeCertified ?? false}
            isProvider={isProvider}
            businessLink={businessLink}
          />
        </div>

        <div className="opp-side-info-wrapper">
          <OpportunityImportantInfo
            locations={locations.data}
            address={address}
            startDate={contractStartDate ? contractStartDate : 'N/A'}
            endDate={contractEndDate ? contractEndDate : 'N/A'}
            estimatedValue={estimatedValue.data?.attributes.name ?? 'N/A'}
            logo={oppLogo}
          />

          {opportunityStatus === opportunityStatusEnum.closedAndGranted && (
            <OpportunityClaimedByInfo
              claimedByBusinesses={claimedByBusinesses.data}
            ></OpportunityClaimedByInfo>
          )}

          <OpportunityContactInfo
            contactFirstname={contactFirstname}
            contactLastname={contactLastname}
            phoneNumber={phone}
            email={email}
            website={website}
            deadline={deadline}
            contactRef={contactRef}
          />
        </div>
      </div>

      <div className="opp-register-section">
        <Register />
      </div>

      <div className="opp-fixed-footer">
        <div className="value-time">
          <p className="bold">
            {estimatedValue.data?.attributes.name ?? 'N/A'}
          </p>
          <p>
            {contractStartDate || contractEndDate
              ? `${
                  contractStartDate
                    ? upperFirstChar(
                        new Date(
                          contractStartDate + 'T00:00:00'
                        ).toLocaleString(currentLocale, {
                          month: 'short',
                          year: 'numeric',
                        })
                      )
                    : 'N/A'
                } - ${
                  contractEndDate
                    ? upperFirstChar(
                        new Date(contractEndDate + 'T00:00:00').toLocaleString(
                          currentLocale,
                          {
                            month: 'short',
                            year: 'numeric',
                          }
                        )
                      )
                    : 'N/A'
                }`
              : 'Contract dates not specified'}
          </p>
        </div>
        <button onClick={scrollToContactInfo}>See contact info</button>
      </div>
    </div>
  );
};

export default OpportunityPageMain;
